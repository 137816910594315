<template>
    <div class="insurances">
        <div class="header">
            <a-icon @click="$router.go(-1)" type="left" />
            <span class="title">{{$t('insurance.myInsurance.title')}}</span>
            <a-icon type="unordered-list" @click="$router.push({name:'insuranceDividends'})" />
        </div>
        <div class="list">
            <div
                class="insurance-item"
                v-for="(x, i) in data"
                :key="'insurance_' + i"
            >
                <div class="box">
                    <div class="period">
                        {{ $t('insurance.types')[x.period] }}
                    </div>
                    <div class="flex">
                        <div class="bid"> #{{ x.id }} </div>
                        <div class="time color-gray"> {{ x.block_timestamp }} </div>
                    </div>
                    <div class="flex">
                        <div class="amount color-primary">
                            {{ x.amount / 1000000 }} USDT
                        </div>
                        <a-tag :color="tagColors[x.state]">
                            {{ $t('insurance.status')[x.state] }}
                        </a-tag>
                    </div>
                    <div class="progress">
                    <a-progress :stroke-color="{from: '#108ee9',to: '#87d068',}" :percent="percent(x)" status="active" />

                    </div>
                    <div class="expand" v-if="x.expand==1">
                        <div class="li">
                            <span class="label">{{ $t('insurance.myInsurance.payout') }}:</span> <span class="val">{{ ((x.total_profit+x.extract_profit)/1000000).toFixed(2) }}</span> 
                        </div>
                        <div class="li">
                            <span class="label">{{ $t('insurance.myInsurance.extracted') }}:</span> <span class="val">{{ (x.extract_profit/1000000).toFixed(2) }}</span>
                        </div>
                        <div class="li">
                            <span class="label">{{ $t('insurance.myInsurance.maturity') }}:</span> <span class="val">{{ x.endtime }}</span>
                        </div>
                        <div v-if="x.type==0">
                            <div class="li">
                                <span class="label">{{ $t('insurance.myInsurance.blockNumber') }}:</span> <span class="val">{{ x.block_number }}</span>
                            </div>
                            <div class="li">
                                <span class="label">{{ $t('insurance.myInsurance.hash') }}:</span>
                                <span class="a-link" @click="tronscanTx(x.tx_id)">{{ x.tx_id }}</span>
                                <a-icon @click="tronscanTx(x.tx_id)" type="monitor" />
                            </div>
                        </div>
                        
                        <div class="btns">
                            <a-button class="btn" v-if="x.total_profit>0" size="small" icon="upload" @click="extractProfitModal(x)"> {{ $t('insurance.myInsurance.extractBtn') }} </a-button>
                            <a-button v-if="x.state==0" class="btn" size="small" type="danger" icon="poweroff" @click="terminateModal(x)"> {{ $t('insurance.myInsurance.terminateBtn') }} </a-button>
                            <a-button v-if="x.state==1" class="btn" size="small" type="primary" icon="wallet" @click="loanModal(x)"> {{ $t('insurance.myInsurance.loanBtn') }} </a-button>
                            <a-button v-if="x.state==2" class="btn" size="small" type="primary" icon="unlock" @click="releaseModal(x)"> {{ $t('insurance.myInsurance.repayBtn') }} </a-button>
                        </div>
                    </div>
                    <div class="center arrow">
                        <a-icon v-if="x.expand==0" type="caret-down" @click="x.expand=1" />
                        <a-icon v-else type="caret-up" @click="x.expand=0" />
                    </div>
                </div>
            </div>

            <div
                v-if="showLoadingMore"
                :style="{
                    textAlign: 'center',
                    marginTop: '12px',
                    height: '32px',
                    lineHeight: '32px',
                }"
            >
                <a-spin v-if="loading" />
                <a-button v-else type="dashed" ghost @click="getData()">
                    {{ $t("common.more") }}
                </a-button>
            </div>
        </div>
        <a-modal v-model="extractProfitVisible" :title="$t('insurance.myInsurance.models.extract.title')" @ok="extractProfit">
            <p>{{ $t('insurance.myInsurance.models.extract.ps0',{currentInterest:curInsuranceInfo.total_profit/1000000}) }}</p>
            <p>{{ $t('insurance.myInsurance.models.extract.ps1') }}</p>
        </a-modal>
        <a-modal v-model="terminateVisible" :title="$t('insurance.myInsurance.models.terminate.title')" @ok="terminateInsurance">
            <p>{{ $t('insurance.myInsurance.models.terminate.ps0',{insuranceId: '#'+curInsuranceInfo.id}) }}</p>
            <p>{{ $t('insurance.myInsurance.models.terminate.ps1',{type: $t('insurance.types')[curInsuranceInfo.period], 'interest': Math.round(curInsuranceInfo.amount * ([90,366][curInsuranceInfo.period]*3/1000)/1000000,2)}) }}</p>
            <p>{{ $t('insurance.myInsurance.models.terminate.ps2') }}</p>
        </a-modal>
        <a-modal v-model="loanVisible" :title="$t('insurance.myInsurance.models.loan.title')" @ok="loan" :confirm-loading="confirmLoading">
            <p>{{ $t('insurance.myInsurance.models.loan.ps0',{insuranceId: curInsuranceInfo.id}) }}</p>
            <p>{{ $t('insurance.myInsurance.models.loan.ps1',{credit:curInsuranceInfo.amount/1000000}) }}</p>
            <p>{{ $t('insurance.myInsurance.models.loan.ps2',{amount:curInsuranceInfo.amount/1000000}) }}</p>
        </a-modal>
        <a-modal v-model="releaseVisible" :title="$t('insurance.myInsurance.models.release.title')" @ok="releaseLoan" :confirm-loading="confirmLoading">
            <p>{{ $t('insurance.myInsurance.models.release.ps0',{insuranceId: curInsuranceInfo.id}) }}</p>
            <p>{{ $t('insurance.myInsurance.models.release.ps1',{amount: curInsuranceInfo.amount/1000000}) }}</p>
        </a-modal>
    </div>
</template>
<script>
export default {
    name: "Insurances",
    data() {
        return {
            loading: true,
            showLoadingMore: true,
            loanVisible: false,
            terminateVisible: false,
            extractProfitVisible: false,
            releaseVisible: false,
            confirmLoading: false,
            approvedAmount: 0,
            userUsdtBalance: 0,
            page: 1,
            pageSize: 10,
            walletAddress: '',
            tagColors: ['','green','cyan','blue','purple','',''],
            curInsuranceInfo: {},
            data: [],
        };
    },
    mounted() {
        let _this = this;
        let _obj = setInterval(()=>{
        if (window.walletAddress && window.insuranceContract && window.usdtToken) {
            clearInterval(_obj)
            _this.getApprovedAmount();
            _this.walletAddress = window.walletAddress;
            window.usdtToken.balanceOf(window.walletAddress).call().then((res)=>{
                _this.userUsdtBalance = res.toNumber();
                console.log(_this.userUsdtBalance);
            })
        }
        }, 50);
        this.getData();
    },
    methods: {
        getApprovedAmount(){
            let _this = this;
            window.usdtToken.allowance(window.walletAddress, window._INSURANCE_CONTRACT).call().then((res)=>{
              let _approvedAmount=0;
              if(res.remaining===undefined){
                _approvedAmount = res.toNumber();
              }else{
                _approvedAmount = res.remaining.toNumber();
              }
              _this.approvedAmount = _approvedAmount;
            });
        },
        async releaseLoan(){
            let _this = this;
            let usdtAmount = this.curInsuranceInfo.amount;
            console.log(usdtAmount, this.userUsdtBalance);
            if(this.userUsdtBalance < usdtAmount){
                this.$message.info(this.$t('common.lackOfBalance'));
                return;
            }
            console.log(_this.approvedAmount, usdtAmount);
            try{
                if(this.approvedAmount < usdtAmount){
                    //授權
                    window.usdtToken.increaseApproval(window._INSURANCE_CONTRACT, _this.curInsuranceInfo.amount*10).send().then(()=>{
                        setTimeout(()=>{
                            _this.getApprovedAmount();
                        },1500);
                        if(window.insuranceContract){
                            window.insuranceContract.releaseInsurance(_this.curInsuranceInfo.amount, _this.curInsuranceInfo.id).send().then(()=>{
                                _this.$message.success(_this.$t('common.submitSuccess'));
                                setTimeout(()=>{
                                    _this.$router.go(0);
                                },1500);
                            });
                        }
                    });
                }else{
                    if(window.insuranceContract){
                        window.insuranceContract.releaseInsurance(_this.curInsuranceInfo.amount, _this.curInsuranceInfo.id).send().then(()=>{
                            _this.$message.success(_this.$t('common.submitSuccess'));
                            setTimeout(()=>{
                                _this.$router.push({name:'wallet'});
                            },1500);
                        });
                    }
                }
            }catch(e){
                console.error(e)
            }
        },
        getData(reset = 0) {
            if (reset == 1) {
                // this.pageSize = 10;
                this.page = 1;
                this.data = [];
                this.showLoadingMore = true;
            }
            this.loading = true;
            this.$http
                .call(this.$http.api.insurances, {
                    data: { page: this.page, size: this.pageSize },
                })
                .then(
                    (res) => {
                        console.log(res);
                        this.loading = false;
                        let resp = res.data;
                        if (resp.code == "200") {
                            this.page++;
                            resp.data.map((x)=>{
                                x.expand = 0;
                            });
                            this.data = this.data.concat(resp.data);
                            if (resp.data.length < this.pageSize) {
                                this.showLoadingMore = false;
                            }
                        } else {
                            this.$message.error(resp.data);
                        }
                    },
                    (res) => {
                        console.log("something goes wrong...");
                        console.log(res.response);
                        if (res.response.status == "401") {
                            this.$store.commit("setToken", "");
                            this.$router.push({ name: "login" });
                        }
                    }
                )
                .then(() => {});
        },
        percent(x){
            let  startDate = Date.parse(x.block_timestamp);
            let  endDate = new Date();
            if (startDate>=endDate){
                return 0;
            }
            let days=(endDate - startDate)/(1*24*60*60*1000);
            let _period = x.period==0?90:366;
            return  Math.round(10000*days/_period)/100;
        },
        tronscanTx(tx_id){
            window.open("https://tronscan.org/#/transaction/"+tx_id);
        },
        loanModal(x){
            this.loanVisible = true;
            this.curInsuranceInfo = x;
        },
        loan(){
            console.log(this.curInsuranceInfo);
            this.confirmLoading = true;
            let data = { bid: this.curInsuranceInfo.id };
            this.$http.call(this.$http.api.insuranceLoan, {data})
                .then((res) => {
                    this.confirmLoading = false;
                    let resp = res.data;
                    if (resp.code == "200") {
                        console.log(resp.data);
                        this.getData(1);
                    } else {
                        this.$message.error(resp.data);
                    }
                },(res) => {
                    console.log("something goes wrong...");
                    console.log(res.response);
                    if (res.response.status == "401") {
                        this.$store.commit("setToken", "");
                        this.$router.push({ name: "login" });
                    }
                }).then(() => {
                    this.confirmLoading = false;
                    this.loanVisible = false;
                });
        },
        extractProfitModal(x){
            this.extractProfitVisible = true;
            this.curInsuranceInfo = x;
        },
        extractProfit(){
            console.log(this.curInsuranceInfo);
            this.confirmLoading = true;
            let data = { bid: this.curInsuranceInfo.id };
            this.$http.call(this.$http.api.insuranceExtract, {data})
                .then((res) => {
                    this.confirmLoading = false;
                    let resp = res.data;
                    if (resp.code == "200") {
                        console.log(resp.data);
                        this.getData(1);
                    } else {
                        this.$message.error(resp.data);
                    }
                },(res) => {
                    console.log("something goes wrong...");
                    console.log(res.response);
                    if (res.response.status == "401") {
                        this.$store.commit("setToken", "");
                        this.$router.push({ name: "login" });
                    }
                }).then(() => {
                    this.confirmLoading = false;
                    this.extractProfitVisible = false;
                });
        },
        terminateModal(x){
            this.terminateVisible = true;
            this.curInsuranceInfo = x;
        },
        terminateInsurance(){
            console.log(this.curInsuranceInfo);
            this.confirmLoading = true;
            let data = { bid: this.curInsuranceInfo.id };
            this.$http.call(this.$http.api.insuranceTerminate, {data})
                .then((res) => {
                    this.confirmLoading = false;
                    let resp = res.data;
                    if (resp.code == "200") {
                        console.log(resp.data);
                        this.getData(1);
                    } else {
                        this.$message.error(resp.data);
                    }
                },(res) => {
                    console.log("something goes wrong...");
                    console.log(res.response);
                    if (res.response.status == "401") {
                        this.$store.commit("setToken", "");
                        this.$router.push({ name: "login" });
                    }
                }).then(() => {
                    this.confirmLoading = false;
                    this.terminateVisible = false;
                });
        },
        releaseModal(x){
            this.releaseVisible = true;
            this.curInsuranceInfo = x;
        },
    },
};
</script>
<style lang="less" scoped>
.insurances {
    padding: 70px 15px;
    width: 100%;
    .header{
        padding: 0 15px;
    }
    .list {
        .insurance-item {
            padding-bottom: 20px;
            .box{
                padding: 20px 10px 5px;
                text-align: left;
                border-radius: 7px;
                position: relative;
                .period{
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    background: @green;
                    padding: 0 3px;
                    font-size: 12px;
                    border-radius: 3px;
                }
                .progress{
                    padding-top: 5px;
                    padding-right: 15px;
                }
                .arrow{
                    color: #a8a8a8;
                }
                .flex{
                    align-items: flex-end;
                }
                .amount {
                    font-weight: bold;
                    text-align: left;
                    font-size: 20px;
                    padding-top: 15px;
                }
                .expand{
                    .li{
                        padding: 3px 0;
                        word-break: break-all;
                        .val{
                            padding: 0 5px;
                        }
                    }
                    .btns {
                        margin-top: 3px;
                        text-align: right;
                        display: flex;
                        justify-content: flex-end;
                        .btn:first-child {
                            margin-right: 5px;
                        }
                    }
                }
                
            }
        }
    }
}
</style>
